import { request } from "../api/_base";
import { FILTER } from "../constants";

export const getSearchResult = async ({
  keyword,
  page,
  size,
  ip,
  type,
  time,
  searchtype,
  restype,
  line = 0,
}) => {
  if (keyword == null || keyword == undefined) {
    return null;
  }
  const params = {
    kw: keyword,
    page: page || 1,
    line,
    ip,
    site: "dalipan",
  };

  if (FILTER.type[type]) {
    params.category = FILTER.type[type].value;
  }
  if (FILTER.time[time]) {
    params.diffDay = FILTER.time[time].value;
  }
  if (FILTER.searchtype[searchtype]) {
    params.searchType = FILTER.searchtype[searchtype].value;
  }
  if (FILTER.restype[restype]) {
    params.resType = FILTER.restype[restype].value;
  }
  if (FILTER.size[size]) {
    if (FILTER.size[size].minSize) {
      params.minSize = FILTER.size[size].minSize;
    } else {
      params.minSize = 1;
    }
    if (FILTER.size[size].maxSize) {
      params.maxSize = FILTER.size[size].maxSize;
    } else {
      params.maxSize = 1099511627776;
    }
  }

  params.site = "personal-search";

  const data = await request({
    url: "/api/v1/pan/search",
    params,
    headers: {
      "X-Authorization": process.server ? "" : localStorage.getItem("token"),
    },
  });
  return data;
};

export const getDetail = async ({ id, size = 15, ip, type, parent }) => {
  return await request({
    url: "/api/v1/pan/detail",
    params: {
      id: id,
      size: size,
      ip,
      type,
      parent,
      site: "personal-search",
    },
    headers: {
      "X-Authorization": process.server ? "" : localStorage.getItem("token"),
    },
  });
};

export const checkUrlFromBaidu = async (data) => {
  return (
    (await request({
      url: "/api/v1/pan/checkUrlValidFromBaidu",
      params: { data },
    })) || {}
  );
};
