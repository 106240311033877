<template>
  <div class="filter-wrap">
    <div class="filter-line">
      <span class="label">搜索模式:</span>
      <div class="filter-button-wrap">
        <span
          class="filter-button"
          :class="{ active: item == filter.searchtype }"
          v-for="item in filter_search_type_keys"
          :key="FILTER.searchtype[item].name"
          @click.stop="chooseFilterSearchType(item)"
        >
          {{ FILTER.searchtype[item].name }}
        </span>
      </div>
    </div>

    <div class="filter-line">
      <span class="label">文件类型:</span>
      <div class="filter-button-wrap">
        <span
          v-for="item in filter_type_keys"
          :key="FILTER.type[item].name"
          @click.stop="chooseFilterType(item)"
          class="filter-button"
          :class="{ active: item == filter.type }"
        >
          {{ FILTER.type[item].name }}
        </span>
      </div>
    </div>

    <div class="filter-line size">
      <span class="label">文件大小:</span>
      <div class="filter-button-wrap">
        <span
          v-for="item in filter_size_keys"
          :key="FILTER.size[item].name"
          @click.stop="chooseFilterSize(item)"
          class="filter-button"
          :class="{ active: item == filter.size }"
        >
          {{ FILTER.size[item].name }}
        </span>
      </div>
    </div>
    <div class="filter-line">
      <span class="label">文件时间:</span>
      <div class="filter-button-wrap">
        <span
          v-for="item in filter_time_keys"
          :key="FILTER.time[item].name"
          @click.stop="chooseFilterTime(item)"
          class="filter-button"
          :class="{ active: item == filter.time }"
        >
          {{ FILTER.time[item].name }}
        </span>
      </div>
    </div>

    <div class="filter-line from">
      <span class="label">文件来源:</span>
      <div class="filter-button-wrap">
        <span
          v-for="item in filter_res_type_keys"
          :key="FILTER.restype[item].name"
          @click.stop="chooseFilterResType(item)"
          class="filter-button"
          :class="{ active: item == filter.restype }"
        >
          {{ FILTER.restype[item].name }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { FILTER } from "@/constants";
const TIME_FILTER_KEYS = Object.keys(FILTER.time).sort();
const TYPE_FILTER_KEYS = Object.keys(FILTER.type).sort();
const SIZE_FILTER_KEYS = Object.keys(FILTER.size).sort();
const SEARCH_TYPE_FILTER_KEYS = Object.keys(FILTER.searchtype).sort();
const RES_TYPE_KEYS = Object.keys(FILTER.restype).sort();

export default {
  props: ["keyword", "time", "type", "size", "searchtype", "restype"],
  data() {
    return {
      arrow: require("@/assets/images/arrow.png"),
      clear: require("@/assets/images/clear.png"),
      index: 0,
      FILTER: FILTER,
      filter_time_keys: TIME_FILTER_KEYS,
      filter_type_keys: TYPE_FILTER_KEYS,
      filter_size_keys: SIZE_FILTER_KEYS,
      filter_res_type_keys: RES_TYPE_KEYS,
      filter_search_type_keys: SEARCH_TYPE_FILTER_KEYS,
    };
  },
  watch: {
    time(data) {
      this.$store.commit("changeFilterTime", data);
    },
    size(data) {
      this.$store.commit("changeFilterSize", data);
    },
    type(data) {
      this.$store.commit("changeFilterType", data);
    },
    searchtype(data) {
      this.$store.commit("changeFilterSearchType", data);
    },
    restype(data) {
      this.$store.commit("changeFilterResType", data);
    },
  },
  computed: {
    filter() {
      return this.$store.state.filter;
    },
  },
  methods: {
    chooseFilterItem(index, event) {
      this.$store.commit("showFilter");
      this.index = index;
    },
    chooseFilterTime(index) {
      this.$store.commit("changeFilterTime", parseInt(`${index}`));
      this.updatePage();
    },
    chooseFilterSize(index) {
      this.$store.commit("changeFilterSize", parseInt(`${index}`));
      this.updatePage();
    },
    chooseFilterType(index) {
      this.$store.commit("changeFilterType", parseInt(`${index}`));
      this.updatePage();
    },
    chooseFilterSearchType(index) {
      this.$store.commit("changeFilterSearchType", parseInt(`${index}`));
      this.updatePage();
    },
    chooseFilterResType(index) {
      this.$store.commit("changeFilterResType", parseInt(`${index}`));
      this.updatePage();
    },
    clearFilter() {
      this.$store.commit("changeFilter", {
        time: -1,
        type: -1,
        size: -1,
        searchtype: -1,
        restype: -1,
      });
      this.updatePage();
    },
    updatePage() {
      const query = {
        keyword: this.keyword,
      };
      if (this.filter.size !== -1) {
        query.size = this.filter.size;
      }
      if (this.filter.time !== -1) {
        query.time = this.filter.time;
      }
      if (this.filter.type !== -1) {
        query.type = this.filter.type;
      }
      if (this.filter.searchtype !== -1) {
        query.searchtype = this.filter.searchtype;
      }
      if (this.filter.restype !== -1) {
        query.restype = this.filter.restype;
      }
      this.$router.push({ path: "search", query });
      this.$store.commit("hideFilter");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";

.filter-wrap {
  .filter-line {
    display: flex;
    align-items: flex-start;
    .filter-button-wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .label {
      flex: none;
      margin-top: 5px;
      margin-right: 15px;
    }
  }
  .filter-button {
    margin-bottom: 12px;
    flex: none;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 13px;
    border: 1px solid #ddd;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    transition: all ease 0.2s;
    &:hover,
    &.active {
      background-color: #eee;
    }
  }
}
@media (max-width: 700px) {
  .filter-wrap {
    font-size: 10px;
    .filter-line {
      .label {
        margin-top: 2px;
      }
    }
    .filter-button {
      border-radius: 4px;
      padding: 2px;
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }
}
</style>
